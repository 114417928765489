<template>
    <div id="app">
        <!-- router views -->
        <transition appear name="fade" mode="out-in">
            <router-view :key="name" />
        </transition>
    </div>
</template>

<script>
import store from '@/js/store';

const Main = () => import('@/views/Main.vue');
const Cms = () => import('@/views/Cms.vue');

export default {
    data: () => ({
        name: '',
    }),
    components: {},
    computed: {
        pages() {
            let pages = this.$store.pages;
            if (pages.length > 0) {
                return pages;
            }
        },
    },
    async created() {
        console.clear();
        // console.log('app.vue');

        await this.$store.getPages();
        this.createRoutes();
    },
    watch: {
        $route: async function (to, from) {
            this.name = to.name == '' || to.name == null ? 'home' : to.name;
            // console.log('route change', from.name, '=>', this.name);
            await this.$store.getQuarks(this.name);
        },
    },
    methods: {
        createRoutes() {
            // get current routes to check if route already exist before adding
            let routes = this.$router.getRoutes();

            // always add '/' route
            let element = {
                path: '/',
                name: '',
                component: Main,
                props: { route: '/' },
                meta: { title: 'Praktijk voor psychotherapie, supervisie en leertherapie' }
            };
            if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

            // add routes from cms
            let response = this.$store.pages;
            for (let i = 0; i < response.length; i++) {
                let path = response[i].path;
                let title = response[i].title;
                let component;

                element = {
                    path: '/' + path,
                    name: path,
                    component: Main,
                    props: { route: path },
                    meta: { title: title }
                };

                if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);
            }

            // always add '/cms' route
            element = {
                path: '/cms',
                name: 'cms',
                component: Cms,
                props: { route: '/cms' },
            };
            if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

            // uncomment the following lines to generate info for sitemap.txt file
            // routes = this.$router.getRoutes();
            // let url = 'https://test.psypraktijkjanssen.nl';
            // routes.forEach((element) => {
            //     console.log(url + element.path);
            // });
        },
    },
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
    font-family: OpenSans;
    src: url('assets/font/OpenSans/OpenSans-VariableFont_wdth,wght.ttf');
}

@font-face {
    font-family: Montserrat;
    src: url('assets/font/Montserrat/Montserrat-VariableFont_wght.ttf');
}

body {
    height: 100vh;
}

#app {
    font-family: 'OpenSans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #333333;
}

h1 {
    color: #2bc10c;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: bold;
}

h2 {
    color: #2bc10c;
    font-size: 22px;
    font-weight: bold;
}

h3 {
    color: #2bc10c;
    margin-top: 18px;
    font-size: 18px;
    font-weight: 400;
    font-style: bold;
    line-height: 56px;
}

p {
    font-size: 18px;
    font-weight: 400;
}

a {
    color: #2bc10c;
}

a:hover {
    color: inherit;
    text-decoration: underline;
}

.bg-black {
    background-color: #111111;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
